import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/1080p_Series_CGI_List/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "1080p CGI Commands",
  "path": "/1080p_Series_CGI_List/Alarm_Menu/Alarm_Server/",
  "dateChanged": "2018-03-23",
  "author": "Mike Polinowski",
  "excerpt": "Camera API control for developers",
  "image": "../../AU_SearchThumb_CGICommands_1080p.png",
  "social": "/images/Search/AU_SearchThumb_CGICommands_1080p.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_CGIs_white.webp",
  "chapter": "1080p CGI Commands"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='1080p CGI Commands' dateChanged='2018-03-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='Camera API control for developers' image='/images/Search/AU_SearchThumb_CGICommands_1080p.png' twitter='/images/Search/AU_SearchThumb_CGICommands_1080p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/1080p_Serie_CGI_Befehle/Alarm_Menu/Alarm_Server/' locationFR='/fr/1080p_Series_CGI_List/Alarm_Menu/Alarm_Server/' crumbLabel="1080p CGI Commands" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "the-alarm-menu--alarm-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#the-alarm-menu--alarm-server",
        "aria-label": "the alarm menu  alarm server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Alarm Menu :: Alarm Server`}</h1>
    <hr></hr>
    <EuiSpacer mdxType="EuiSpacer" />
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#paramcgicmdgetmdalarm-anameserver2"
        }}>{`param.cgi?cmd=getmdalarm&-aname=server2`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#get-alarm-server-1-parameter"
            }}>{`Get Alarm Server [1] Parameter`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#example"
            }}>{`Example`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#paramcgicmdgetalarmserverattr-as_index3"
        }}>{`param.cgi?cmd=getalarmserverattr&-as_index=3`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#get-alarmserver-2-configuration"
            }}>{`Get Alarmserver [2] Configuration`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#example-1"
            }}>{`Example`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "paramcgicmdgetmdalarm-anameserver2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgetmdalarm-anameserver2",
        "aria-label": "paramcgicmdgetmdalarm anameserver2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=getmdalarm&-aname=server2`}</h2>
    <h3 {...{
      "id": "get-alarm-server-1-parameter",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#get-alarm-server-1-parameter",
        "aria-label": "get alarm server 1 parameter permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get Alarm Server `}{`[1]`}{` Parameter`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`md_server2_switch`}</code>{`: De/Activate Alarm Server2 `}{`[off, on]`}</li>
    </ul>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getmdalarm`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-aname`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`server2
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`md_server2_switch`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"off"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`md_server2_interval`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

GET: http://192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getmdalarm`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-aname`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`server2`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-pwd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`md_server2_switch`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"off"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`md_server2_interval`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

SET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setmdalarm`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-aname`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`server2`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-switch`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`on`}</code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Update`}</strong>{`: Set the alarm server interval (cooldown) in `}{`[s]`}{` with `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`/param.cgi?cmd=setmdalarm&-aname=server2&-switch=on&-interval=60`}</code>{` - The default interval is 60s. You can reduce the interval down to 1s.`}</p>
    </blockquote>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdgetalarmserverattr-as_index3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgetalarmserverattr-as_index3",
        "aria-label": "paramcgicmdgetalarmserverattr as_index3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=getalarmserverattr&-as_index=3`}</h2>
    <h3 {...{
      "id": "get-alarmserver-2-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#get-alarmserver-2-configuration",
        "aria-label": "get alarmserver 2 configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get Alarmserver `}{`[2]`}{` Configuration`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`as_server[2]`}</code>{`: Address of the receiving Server (e.g. Home Automation Server) `}{`[IPv4 Address]`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`as_port[2]`}</code>{`: Port of the receiving Server `}{`[1-65536]`}{` |`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`as_auth[2]`}</code>{`: Authentication required `}{`[0, 1]`}{` |`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`as_username[2]`}</code>{`: Alarmserver Username |`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`as_password[2]`}</code>{`: Alarmserver Password |`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`as_path[2]`}</code>{`: URL Path |`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`as_area[2]`}</code>{`: Send Query when Motion is Detected |`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`as_io[2]`}</code>{`: Send Query when Alarm Input is Triggered |`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`as_audio[2]`}</code>{`: Send Query when Audio Alarm is Triggered |`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`as_areaio[2]`}</code>{`: Send Query when Motion is Detected and Input is Triggered |`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`as_activequery[2]`}</code>{`: Append Alarm Trigger to Query |`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`as_query1[2]`}</code>{`: Activate Sending optional Parameter 1 |`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`as_queryattr1[2]`}</code>{`: Command 1 Attribute |`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`as_queryval1[2]`}</code>{`: Command 1 Value |`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`as_query2[2]`}</code>{`: Activate Sending optional Parameter 2 |`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`as_queryattr1[2]`}</code>{`: Command 2 Attribute |`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`as_queryval2[2]`}</code>{`: Command 2 Value |`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`as_query3[2]`}</code>{`: Activate Sending optional Parameter 3 |`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`as_queryattr3[2]`}</code>{`: Command 3 Attribute |`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`as_queryval3[2]`}</code>{`: Command 3 Value |`}</li>
    </ul>
    <h3 {...{
      "id": "example-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-1",
        "aria-label": "example 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getalarmserverattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-as_index`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`as_server`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`as_port`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`as_auth`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`as_username`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`as_password`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`as_path`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`as_area`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`as_io`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`as_audio`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`as_areaio`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`as_activequery`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`as_query1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`as_queryattr1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2328824`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`as_queryval1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2329592`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`as_query2`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`as_queryattr2`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2329080`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`as_queryval2`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2329848`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`as_query3`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`as_queryattr3`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2329336`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`as_queryval3`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2330104`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`as_ex`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`as_img`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`as_ssl`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`as_mode`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`


SET: http://192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setalarmserverattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-as_index`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-as_server`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`192.168`}</span>{`.1.114`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-as_port`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`80`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-as_path`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`/`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-as_queryattr1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`command1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-as_queryval1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`false`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-as_queryattr2`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`command2`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-as_queryval2`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`true`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-as_activequery`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-as_auth`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-as_query1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-as_query2`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      